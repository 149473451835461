import React, { useEffect } from "react";
import style from "./alert.module.css";

// Icons
import { Checkmark } from "../../icons/Checkmark";
import { Warning } from "../../icons/Warning";

export const Alert = ({ onClose, status, text, autoClose = false }) => {
  useEffect(() => {
    if (autoClose) {
      console.log("Setting up auto-close timer");
      const timer = setTimeout(() => {
        console.log("Auto-closing alert");
        onClose();
      }, 4000); 

      return () => {
        console.log("Cleaning up timer");
        clearTimeout(timer);
      };
    }
  }, [autoClose, onClose]);

  function getStatus() {
    if (status === "success") {
      return style["success"];
    } else if (status === "warning") {
      return style["warning"];
    }
  }
  return (
    <div className={style["alert"] + " " + getStatus()}>
      {status === "success" ? <Checkmark /> : null}
      {status === "warning" ? <Warning /> : null}
      <span className={style.text}>{text}</span>
      <button className={style["close-btn"]} onClick={onClose}>
        &times;
      </button>
    </div>
  );
};
