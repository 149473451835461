import React, { useEffect, useContext } from "react";
import { AppContext } from "../../context/appContext";
import Header from "./Header";
import MainContent from "./MainContent";
import StarryBackground from "./StarryBackground";

const LandingPage = () => {
  const { setIsLoginModalOpen } = useContext(AppContext);

  useEffect(() => {
    const link = document.createElement("link");
    link.href =
      "https://fonts.googleapis.com/css2?family=Geist:wght@300;400;700&display=swap";
    link.rel = "stylesheet";
    document.head.appendChild(link);
  }, []);

  const handleLoginClick = () => {
    setIsLoginModalOpen(true);
  };

  return (
    <div className="min-h-screen bg-zinc-950 text-zinc-100 font-geist font-light overflow-hidden">
      <StarryBackground />
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 relative z-10">
        <Header handleLoginClick={handleLoginClick} />
        <MainContent handleLoginClick={handleLoginClick} />
      </div>
    </div>
  );
};

export default LandingPage;
