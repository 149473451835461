import React, { useState, useEffect } from 'react';
import CountryDisplay from '../CountryDisplay/CountryDisplay';
import TimeAdjustment from '../TimeAdjustment/TimeAdjustment';
import styles from './TimeMachineControls.module.css';
import "@flaticon/flaticon-uicons/css/all/all.css";

const TimeMachineControls = ({ countryName, setSearchInput, visible, selectedYear, searchInput }) => {
  const [useAdaptiveStyle, setUseAdaptiveStyle] = useState(false);

  // Add debug logging
  useEffect(() => {
    console.debug('TimeMachineControls - searchInput:', {
      yearRange: searchInput?.yearRange,
      fullSearchInput: searchInput
    });
  }, [searchInput]);

  useEffect(() => {
    const handleUIStyle = (event) => {
      setUseAdaptiveStyle(event.detail.useAdaptiveStyle);
    };

    window.addEventListener('uiStyleChange', handleUIStyle);
    return () => window.removeEventListener('uiStyleChange', handleUIStyle);
  }, []);

  return (
    <div className={`${styles.controlsContainer} ${useAdaptiveStyle ? styles.adaptive : ''}`}>
      <CountryDisplay 
        countryName={countryName}
        useAdaptiveStyle={useAdaptiveStyle}
        yearRange={searchInput?.yearRange}
      />
      <div className={`${styles.dividerContainer} ${useAdaptiveStyle ? styles.adaptive : ''}`}>
        <div className={styles.divider}></div>
        <i className={`fi fi-rr-globe ${styles.globeIcon} ${useAdaptiveStyle ? styles.adaptive : ''}`}></i>
        <div className={styles.divider}></div>
      </div>
      <TimeAdjustment
        setSearchInput={setSearchInput}
        visible={visible}
        selectedYear={selectedYear}
        useAdaptiveStyle={useAdaptiveStyle}
      />
    </div>
  );
};

export default TimeMachineControls;
