import React, { useState, useEffect } from "react";
import SuggestedPromptCards from "../SuggestedPromptCards/SuggestedPromptCards";
import ToggleDictation from "../DictationButton/ToggleDictation";
import PreviewCard from "../PreviewCard/PreviewCard";
import { DropDownSelector } from "../DropDownSelector/DropDownSelector";
import SubmitGeneration from "./SubmitGeneration";
import MinimalLengthSelector from "../MinimalLengthSelector";
import styles from "./PromptInputContainer.module.css";

// Import images
import Comic from "../../images/comic.png";
import Digital from "../../images/digital.png";
import Gothic from "../../images/gothic.png";
import Impressionism from "../../images/impressionism.png";

const PromptInputContainer = ({
  children,
  setIdeaInputText,
  interimTranscript,
  setInterimTranscript,
  isListening,
  setIsListening,
  onPresetPromptClick,
  generatedImageUrl,
  isLoading,
  handleChange,
  ideaInputText,
  showNotification,
  inputRef,
  setAlertOptions,
  dropDownOptions,
  setDropDownOptions,
  setUserData,
  handleSubmit,
}) => {
  const [showPlaceholder, setShowPlaceholder] = useState(true);
  const [length, setLength] = useState("Auto");

  useEffect(() => {
    setShowPlaceholder(
      ideaInputText.length === 0 && interimTranscript.length === 0
    );
  }, [ideaInputText, interimTranscript]);

  const handleDictationTextChange = (text) => {
    setIdeaInputText((prevText) => prevText + (prevText ? " " : "") + text);
  };

  const selectOption = (option, type) => {
    setDropDownOptions((prev) => ({ ...prev, [type]: option }));
  };

  return (
    <div className="flex flex-col items-center w-full max-width-[700px] mx-auto box-border">
      <SuggestedPromptCards onPromptClick={onPresetPromptClick} />
      <div className="w-full flex flex-col gap-5">
        <div className={`relative w-full ${styles.glassyContainer}`}>
          <div className="relative">
            {showNotification && (
              <div className="absolute top-[-2.5rem] left-1/2 transform -translate-x-1/2 bg-black bg-opacity-70 text-white p-2 rounded text-sm z-50 animate-fadeInOut">
                Character limit reached
              </div>
            )}
            <textarea
              ref={inputRef}
              id="story-prompt"
              name="storyPrompt"
              rows="5"
              placeholder="Ask anything you want."
              autoFocus
              onChange={handleChange}
              value={`${ideaInputText}${interimTranscript}`}
              maxLength={4500}
              className={`w-full p-2.5 box-border min-h-[60px] rounded text-[#e6e6e6] text-xl font-roboto focus:outline-none ${
                generatedImageUrl ? "pr-32 xl:pr-36 2xl:pr-44" : "pr-15"
              } placeholder-transparent resize-none ${styles.glassyTextarea}`}
            ></textarea>
            {showPlaceholder && (
              <div className="absolute top-0 left-0 p-2.5 pointer-events-none text-[#9ca3af] text-xl font-roboto">
                Ask <span className="text-shadow-custom">anything</span> you
                want.
              </div>
            )}
            <div className="absolute top-[17px] right-[14px] z-10 w-6 h-6 flex items-center justify-center cursor-pointer pb-[5px]">
              <ToggleDictation
                onTextChange={handleDictationTextChange}
                interimText={interimTranscript}
                setInterimText={setInterimTranscript}
                setIsListening={setIsListening}
                isListening={isListening}
              />
            </div>
            <PreviewCard
              imageUrl={generatedImageUrl}
              isLoading={isLoading}
              className="absolute bottom-[1px] right-[4px] z-10"
            />
          </div>
          <div className="absolute bottom-[6px] left-[10px] z-10 flex items-center">
            <MinimalLengthSelector
              value={length}
              onChange={(value) => {
                setLength(value);
                selectOption(value, "length");
              }}
            />
          </div>
        </div>
        <div className="flex flex-col gap-5">
          <div className={styles.dropdownsContainer}>
            {[
              {
                type: "voice",
                label: "Voice",
                options: [{ name: "Male" }],
              },
              {
                type: "style",
                label: "Visuals",
                options: [
                  { name: "Digital", img: Digital },
                  { name: "Comic", img: Comic },
                  { name: "Gothic", img: Gothic },
                  { name: "Painterly", img: Impressionism },
                ],
                gallery: true,
              },
              {
                type: "mode",
                label: "Presets",
                visualLabel: "Mode",
                options: [
                  { name: "Torsera" },
                  { name: "Mini-Doc" },
                  { name: "Bizarre" },
                  { name: "Thought-provoking" },
                  { name: "Black-Mirror" },
                  { name: "Unhinged" },
                  { name: "Alan Watts" },
                  { name: "Horror" },
                  { name: "Buddhist Monk" },
                ],
              },
            ].map(({ type, label, visualLabel, options, gallery }) => (
              <DropDownSelector
                key={type}
                label={label}
                visualLabel={visualLabel}
                onClickFunction={selectOption}
                selectList={options}
                text={dropDownOptions[type]}
                type={type}
                gallery={gallery}
              />
            ))}
          </div>
          <SubmitGeneration
            handleSubmit={handleSubmit}
            ideaInputText={ideaInputText}
            dropDownOptions={dropDownOptions}
            setAlertOptions={setAlertOptions}
            setIdeaInputText={setIdeaInputText}
            setUserData={setUserData}
          />
        </div>
        {children}
      </div>
    </div>
  );
};

export default PromptInputContainer;
