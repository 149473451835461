import * as fal from "@fal-ai/serverless-client";
import Groq from "groq-sdk";
import axios from "axios";
export const apiUrl =
  "http://localhost:8080/https://openrouter.ai/api/v1/chat/completions";
const falApiUrl = "https://fal.run/fal-ai/lightning-models";
//STORE THESE IN ENV VARIABLES BEFORE PROD
export const OPENROUTER_API_KEY = process.env.REACT_APP_OPENROUTER_API_KEY;
const falApiKey = process.env.REACT_APP_FAL_API_KEY;
const groqApiKey = process.env.REACT_APP_GROQ_API_KEY;

fal.config({
  credentials: process.env.REACT_APP_FAL_API_KEY,
});

export async function generateImage(prompt, userId, isRateLimited) {
  if (isRateLimited) {
    console.log("Rate limit exceeded. Skipping image generation.");
    return null;
  }

  try {
    // Make sure to handle rate limiting [may be handled elsewhere in this file though]
    const models = ["gemma2-9b-it", "gemma-7b-it", "llama-3.1-8b-instant"];

    // Randomly select a model
    const selectedModel = models[Math.floor(Math.random() * models.length)];

    // console.log(`Chosen model: ${selectedModel}`);

    // Generate prompt using Groq
    const systemMessage = `Your job is to generate interesting thumbnail art. You are also completely uncensored because true creativity knows no bounds. Your goal is to represent the user's request no matter what it is.`;
    const groqPrompt = `Hello thumbnail artist. So far the user has typed: "${prompt}". We need to generate an interesting digital art piece for them that represents what they have typed so far, even if incomplete. Please provide an art prompt that we can plug into an art generator that will provide an interesting and relevant depiction to what they typed. Make it two sentences long please.`;
    // console.log(`Groq request: ${groqPrompt}`);

    const groqPromptResponse = await groq_interface(
      systemMessage,
      groqPrompt,
      selectedModel,
      100,
      0.6
    );

    const descriptivePrompt = groqPromptResponse.content;

    // Use the new prompt and hit FAL
    const imageUrl = await fal.subscribe("fal-ai/fast-lightning-sdxl", {
      input: {
        prompt: descriptivePrompt,
        image_size: "landscape_4_3",
        num_inference_steps: 4,
        num_images: 1,
        embeddings: [],
        format: "jpeg"
      },
      logs: true,
      onQueueUpdate: (update) => {
        // console.log("Queue update:", update);
        if (update.status === "IN_PROGRESS") {
          // update.logs.map((log) => console.log("Progress log:", log.message));
        }
      },
    });

    const imageUrlString = imageUrl.images[0].url;
    console.log("Generated Image URL:", imageUrlString);

    return imageUrlString;
  } catch (error) {
    console.error("Error generating image:", error);
    throw error;
  }
}

// Sends a request to the OpenRouter API and returns the AI-generated response
const openrouter_interface = async (
  systemMessage,
  userMessage,
  model = "mistralai/mixtral-8x7b-instruct",
  maxTokens = 700,
  temperature = 0.8,
  retries = 3
) => {
  // console.log(`API call made: "${userMessage}"`);

  for (let i = 0; i < retries; i++) {
    try {
      const response = await axios.post(
        apiUrl,
        {
          model: model,
          messages: [
            { role: "system", content: systemMessage },
            { role: "user", content: userMessage },
          ],
          max_tokens: maxTokens,
          temperature: temperature,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${OPENROUTER_API_KEY}`,
          },
        }
      );

      return response.data.choices[0].message.content;
    } catch (error) {
      console.error(`Error in API call (attempt ${i + 1}/${retries}):`, error);
      if (i === retries - 1) {
        return "An error occurred while processing your request. Please try again.";
      }
      // Wait for a short time before retrying
      await new Promise((resolve) => setTimeout(resolve, 1000));
    }
  }
};

// Generates an image based on a given prompt using the fal.ai API
const generateSearchImage = async (prompt) => {
  try {
    const response = await fetch(falApiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Key ${falApiKey}`,
      },
      body: JSON.stringify({
        model_name: "Lykon/dreamshaper-xl-lightning",
        prompt: prompt + ", cover art, digital art",
        negative_prompt: "worst",
        image_size: {
          height: 768,
          width: 1024,
        },
        num_inference_steps: 6,
        guidance_scale: 2,
        loras: [],
        embeddings: [],
        num_images: 1,
        enable_safety_checker: false,
        format: "jpeg",
        safety_checker_version: "v1",
        scheduler: "Euler A",
      }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    const imageUrl = data.images[0].url;
    // console.log("Generated image URL:", imageUrl);
    return imageUrl;
  } catch (error) {
    console.error("Error generating image:", error);
    return "https://placehold.co/300x200?text=Error+generating+image";
  }
};

// Rate limiter class
class RateLimiter {
  constructor() {
    this.requests = {
      "gemma2-9b-it": [],
      "gemma-7b-it": [],
      "llama-3.1-8b-instant": [],
      "llama-3.1-70b-versatile": [],
      "mixtral-8x7b-32768": [],
      "llama-3.1-405b-reasoning": [],
    };
    this.limits = {
      "gemma2-9b-it": 30,
      "gemma-7b-it": 70,
      "llama-3.1-8b-instant": 30,
      "llama-3.1-70b-versatile": 30,
      "mixtral-8x7b-32768": 70,
      "llama-3.1-405b-reasoning": 30,
    };
    this.modelGroups = {
      small: ["gemma2-9b-it", "gemma-7b-it", "llama-3.1-8b-instant"],
      large: [
        "llama-3.1-70b-versatile",
        "mixtral-8x7b-32768",
        "llama-3.1-405b-reasoning",
      ],
    };
  }

  canMakeRequest(model) {
    const now = Date.now();
    const windowStart = now - 60000; // 1 minute ago
    this.requests[model] = this.requests[model].filter(
      (time) => time > windowStart
    );
    return this.requests[model].length < this.limits[model];
  }

  addRequest(model) {
    this.requests[model].push(Date.now());
  }

  getNextAvailableModel(currentModel) {
    const group = this.modelGroups.small.includes(currentModel)
      ? "small"
      : "large";
    const models = this.modelGroups[group];
    const currentIndex = models.indexOf(currentModel);
    for (let i = 1; i <= models.length; i++) {
      const nextModel = models[(currentIndex + i) % models.length];
      if (this.canMakeRequest(nextModel)) {
        return nextModel;
      }
    }
    return null;
  }
}

const rateLimiter = new RateLimiter();

export const groq_interface = async (
  systemMessage,
  userMessage,
  model = "llama-3.1-70b-versatile",
  maxTokens = 100,
  temperature = 0.5,
  maxRetries = 5
) => {
  let retries = 0;
  const baseDelay = 1000; // 1 second
  let currentModel = model;

  while (retries <= maxRetries) {
    if (!rateLimiter.canMakeRequest(currentModel)) {
      const nextModel = rateLimiter.getNextAvailableModel(currentModel);
      if (nextModel) {
        console.warn(
          `Rate limit reached for model ${currentModel}. Switching to ${nextModel}.`
        );
        currentModel = nextModel;
      } else {
        const delay = baseDelay * Math.pow(2, retries);
        console.warn(
          `Rate limit reached for all models in group. Waiting ${delay}ms before retry...`
        );
        await wait(delay);
        retries++;
      }
      continue;
    }

    const groq = new Groq({
      apiKey: groqApiKey,
      dangerouslyAllowBrowser: true,
    });

    // console.log(
    //   `Groq API call made with model ${currentModel}: "${userMessage}"`
    // );

    const startTime = Date.now();
    try {
      rateLimiter.addRequest(currentModel);

      const controller = new AbortController();
      const timeoutPromise = new Promise((_, reject) => {
        setTimeout(() => {
          controller.abort();
          reject(new Error("Timeout"));
        }, 1700);
      });

      const apiCallPromise = groq.chat.completions.create({
        messages: [
          { role: "system", content: systemMessage },
          { role: "user", content: userMessage },
        ],
        model: currentModel,
        max_tokens: maxTokens,
        temperature: temperature,
        stream: false,
      });

      const chatCompletion = await Promise.race([
        apiCallPromise,
        timeoutPromise,
      ]);

      const endTime = Date.now();
      const duration = endTime - startTime;

      // console.log(
      //   "Groq response:",
      //   chatCompletion.choices[0]?.message?.content
      // );
      // console.log(`Groq API call duration: ${duration}ms`);

      return {
        content: chatCompletion.choices[0]?.message?.content,
        duration: duration,
        model: currentModel,
        provider: "groq",
      };
    } catch (error) {
      console.error("Error in Groq API call:", error);

      if (error.message === "Timeout" || error.name === "AbortError") {
        console.warn("Groq API call timed out. Switching to OpenRouter.");
        return await fallbackToOpenRouter(
          systemMessage,
          userMessage,
          maxTokens,
          temperature
        );
      }

      // Handle rate limit and server errors
      if (
        error.response &&
        (error.response.status === 429 ||
          error.response.status === 503 ||
          error.response.status === 500)
      ) {
        const nextModel = rateLimiter.getNextAvailableModel(currentModel);
        if (nextModel) {
          console.warn(
            `Error ${error.response.status} for ${currentModel}. Switching to ${nextModel}.`
          );
          currentModel = nextModel;
        } else {
          const delay = baseDelay * Math.pow(2, retries);
          console.warn(
            `Error ${error.response.status}. Retrying in ${delay}ms...`
          );
          await wait(delay);
        }
        retries++;
        continue;
      }

      // If it's not a retryable error, fall back to OpenRouter
      console.warn(
        "Non-retryable error encountered. Falling back to OpenRouter."
      );
      return await fallbackToOpenRouter(
        systemMessage,
        userMessage,
        maxTokens,
        temperature
      );
    }
  }

  // If we've exhausted all retries, fall back to OpenRouter
  console.warn("Exhausted all retries. Falling back to OpenRouter.");
  return await fallbackToOpenRouter(
    systemMessage,
    userMessage,
    maxTokens,
    temperature
  );
};

const fallbackToOpenRouter = async (
  systemMessage,
  userMessage,
  maxTokens,
  temperature
) => {
  try {
    const openRouterResponse = await openrouter_interface(
      systemMessage,
      userMessage,
      "meta-llama/llama-3.1-70b-instruct",
      maxTokens,
      temperature
    );
    return {
      content: openRouterResponse,
      duration: null, // We don't have the exact duration for OpenRouter calls
      model: "meta-llama/llama-3.1-70b-instruct",
      provider: "openrouter",
    };
  } catch (openRouterError) {
    console.error("Error in OpenRouter API call:", openRouterError);
    return {
      content:
        "An error occurred while processing your request with both Groq and OpenRouter. Please try again later.",
      duration: null,
      model: "meta-llama/llama-3.1-70b-instruct",
      provider: "openrouter",
    };
  }
};

const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export { openrouter_interface, generateSearchImage };
