// firebaseutils.js
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/analytics";
import { getFirestore } from "firebase/firestore";
import axios from "axios";

// Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_PROJ_API_KEY,
  authDomain: `${process.env.REACT_APP_FIREBASE_PROJ_ID}.firebaseapp.com`,
  projectId: process.env.REACT_APP_FIREBASE_PROJ_ID,
  storageBucket: `${process.env.REACT_APP_FIREBASE_PROJ_ID}.appspot.com`,
  messagingSenderId: process.env.REACT_APP_MESSAGE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: `${process.env.REACT_APP_GOOGLE_ANALYTICS_ID}`,
};

// Initialize Firebase only if it hasn't been initialized yet
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
  // Optionally initialize analytics if needed
  // firebase.analytics();
} else {
  firebase.app(); // Use the existing app
}

export const db = getFirestore(firebase.app());

export async function handleUpdateBillingDetails(userData) {
  if (userData.stripe_customer_id) {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/create-portal-session`,
        {
          base_url: process.env.REACT_APP_BASE_URL,
          stripe_customer_id: userData.stripe_customer_id,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      if (response.status === 200 && response.data.url) {
        console.log("Redirecting to:", response.data.url);
        window.location.href = response.data.url;
      } else {
        console.error("Failed to create checkout session");
        console.error("Error details:", response.data);
        // Handle this error, maybe show a message to the user
      }
    } catch (error) {
      console.error(
        "Error:",
        error.response ? error.response.data : error.message
      );
      // Handle this error, maybe show a message to the user
    }
  }
}

export async function handleSubscribe(
  userData,
  priceId,
  setIsPricingModalOpen,
  setAlertOptions
) {
  if (!userData || !userData.user_id) {
    console.error("User data or user ID is missing");
    // Handle this error, maybe show a message to the user
    return;
  }

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/create-checkout-session`,
      {
        email: userData.email,
        price_id: priceId,
        user_id: userData.user_id,
        stripe_customer_id: userData.stripe_customer_id,
        base_url: process.env.REACT_APP_BASE_URL,
      },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (response.status === 200 && response.data.url) {
      console.log("Redirecting to:", response.data.url);
      window.location.href = response.data.url;
    } else if (response.status === 200) {
      setIsPricingModalOpen(false);
      setAlertOptions({
        isOpen: true,
        text: "Subscription updated successfully. Please allow a few minutes for the change to take effect.",
        status: "success",
      });
    } else {
      console.error("Failed to create checkout session");
      console.error("Error details:", response.data);
      // Handle this error, maybe show a message to the user
    }
  } catch (error) {
    console.error(
      "Error:",
      error.response ? error.response.data : error.message
    );
    // Handle this error, maybe show a message to the user
  }
}
