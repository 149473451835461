import React, { useState, useEffect } from "react";
import PreviewCard from "./PreviewCard";

const PreviewRateLimit = ({ children }) => {
  const [imageCount, setImageCount] = useState(0);
  const [showToast, setShowToast] = useState(false);
  const [isRateLimited, setIsRateLimited] = useState(false);
  const limit = 20;
  const timeWindow = 30000; // 30 seconds in milliseconds

  useEffect(() => {
    const timer = setTimeout(() => {
      setImageCount(0);
      setIsRateLimited(false);
    }, timeWindow);

    return () => clearTimeout(timer);
  }, [imageCount]);

  const incrementCount = () => {
    setImageCount((prevCount) => {
      const newCount = prevCount + 1;
      if (newCount > limit) {
        setShowToast(true);
        setIsRateLimited(true);
        return limit;
      }
      return newCount;
    });
  };

  const handleCloseToast = () => {
    setShowToast(false);
  };

  return (
    <div style={{ position: 'relative' }}>
      {React.cloneElement(children, { 
        incrementCount, 
        isRateLimited,
        showToast,
        onCloseToast: handleCloseToast
      })}
    </div>
  );
};

export default PreviewRateLimit;
