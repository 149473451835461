import React from "react";

const StarryBackground = () => (
  <div className="fixed top-0 left-0 w-full h-full pointer-events-none">
    {[...Array(82)].map((_, i) => (
      <div
        key={i}
        className="absolute w-0.5 h-0.5 bg-white rounded-full animate-twinkle"
        style={{
          top: `${Math.random() * 100}%`,
          left: `${Math.random() * 100}%`,
          animationDelay: `${i * 0.1}s`,
          animationDuration: "10s",
          opacity: 0,
        }}
      ></div>
    ))}
  </div>
);

export default StarryBackground;
