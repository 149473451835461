import React from "react";
import { FiUser, FiMail, FiLock, FiCreditCard } from "react-icons/fi";
import { motion } from "framer-motion";

const AccountInfo = ({ userData }) => {
  return (
    <motion.div
      className="bg-[#27272A] p-6 rounded-lg shadow-md h-full transition-all duration-300 hover:shadow-lg"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className="flex items-start mb-6 overflow-hidden">
        <motion.div
          className="bg-zinc-700 rounded-full p-4 mr-4 hidden md:block"
          whileHover={{ rotate: 360 }}
          transition={{ duration: 0.6 }}
        >
          <FiUser className="w-12 h-12 text-purple-400" />
        </motion.div>
        <div>
          <h2 className="text-2xl font-semibold text-zinc-100 mb-2">
            Account Info
          </h2>
          <InfoItem icon={FiMail} label="Email" value={userData?.email} />
          <InfoItem
            icon={FiCreditCard}
            label="Credits"
            value={userData?.credits}
          />
          <InfoItem
            icon={FiLock}
            label="Login method"
            value={userData?.providerId || "Email/Password"}
          />
        </div>
      </div>
      {/* <div className="text-zinc-300 space-y-4">
        <InfoItem
          icon={FiLock}
          label="Login method"
          value={userData?.providerId || "Email/Password"}
        />
      </div> */}
    </motion.div>
  );
};

const InfoItem = ({ icon: Icon, label, value }) => (
  <motion.p
    className="flex items-center"
    whileHover={{ x: 5 }}
    transition={{ duration: 0.2 }}
  >
    <Icon className="w-5 h-5 mr-3 text-purple-400" />
    <span className="font-medium mr-2">{label}:</span> {value}
  </motion.p>
);

export default AccountInfo;
