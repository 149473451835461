import React, { useState, useEffect, useCallback, useRef } from "react";
import styles from "./TimeAdjustment.module.css";
import tickTockAudio from "../../../audio/tick-tock-slight-trim.mp3";

const TimeAdjustment = ({ setSearchInput, visible, selectedYear, useAdaptiveStyle }) => {
  const [year, setYear] = useState(2024);
  const [speed, setSpeed] = useState(200);
  const [isHolding, setIsHolding] = useState(false);
  const [holdDirection, setHoldDirection] = useState(0);
  const [displayYear, setDisplayYear] = useState(2024);
  const [isAnimating, setIsAnimating] = useState(false);
  const [isPulsing, setIsPulsing] = useState(false);

  // New state variable for selected range
  const [selectedRange, setSelectedRange] = useState(null);

  const minSpeed = 200;
  const speedFactor = 0.98;
  const rampUpFactor = 1.1;
  const rampUpDuration = 2000;
  const rampUpIntervals = [8000, 16000];
  const initialSpeed = 1000;
  const yearIncrement = 25;

  const animationRef = useRef(null);
  const audioRef = useRef(null);

  const getYearIncrement = useCallback((currentYear, increment) => {
    if (increment < 0 && currentYear === 2024) {
      return 24; // Skip directly to 2000
    } else if (increment > 0 && currentYear === 2000) {
      return 24; // Moving up from 2000 to 2024
    } else if (increment > 0 && currentYear > 2000 && currentYear < 2024) {
      return Math.min(yearIncrement, 2024 - currentYear);
    } else if (increment < 0 && currentYear > 2000 && currentYear <= 2024) {
      return Math.min(yearIncrement, currentYear - 2000);
    }
    return yearIncrement;
  }, []);

  // Add a new function to format the full year range for storage
  const formatFullYearRange = useCallback((year) => {
    if (year === 2024) {
      return "2024";
    } else if (year === 2000) {
      return "2000 - 2023";
    } else if (year > 2000 && year < 2024) {
      return `${year}`;
    } else {
      let rangeEnd = year + 24;
      if (year > 0) {
        return `${year} - ${rangeEnd}`;
      } else {
        const absYear = Math.abs(year);
        const absRangeEnd = Math.abs(rangeEnd);
        return `${absYear} - ${absRangeEnd} BC`;
      }
    }
  }, []);

  // Modify the existing formatYearRange function
  const formatYearRange = useCallback((year) => {
    if (year === 2024) {
      return "2024";
    } else if (year === 2000) {
      return "2000 - '23";
    } else if (year > 2000 && year < 2024) {
      return `${year}`;
    } else {
      let rangeEnd = year + 24;
      if (year > 0) {
        return `${year} - '${rangeEnd.toString().slice(-2)}`;
      } else {
        const absYear = Math.abs(year);
        const absRangeEnd = Math.abs(rangeEnd);
        return `${absYear} - ${absRangeEnd} BC`;
      }
    }
  }, []);

  // New function to determine the year class
  const getYearClass = useCallback((year) => {
    if (year <= -1000) {
      return styles.year1000BC;
    } else if (year < 0) {
      return styles.yearBC;
    }
    return '';
  }, []);

  const updateFontClass = useCallback(() => {
    if (year >= 1900) return styles.font1900;
    if (year >= 1600) return styles.font1600;
    if (year >= 1050) return styles.font1050;
    if (year >= 700) return styles.font700;
    if (year >= 0) return styles.font0;
    if (year >= -1000) return styles.font1000BC;
    return styles.font3500BC;
  }, [year]);

  const animateYearChange = useCallback((start, end, duration = 1000) => {
    const startTime = performance.now();
    setIsAnimating(true);

    const animate = (currentTime) => {
      const elapsedTime = currentTime - startTime;
      const progress = Math.min(elapsedTime / duration, 1);
      const currentYear = Math.round(start + (end - start) * progress);

      // Smoothly transition between 2000 - '23 and single years
      if (currentYear === 2000) {
        setDisplayYear(2000); // Show "2000 - '23"
      } else if (currentYear > 2000 && currentYear < 2024) {
        setDisplayYear(currentYear); // Show individual years from 2001 to 2023
      } else {
        setDisplayYear(currentYear); // Show "2024" when we reach it
      }

      if (progress < 1) {
        animationRef.current = requestAnimationFrame(animate);
      } else {
        setDisplayYear(end);
        setIsAnimating(false);
        setIsPulsing(true); // Trigger pulse when animation completes
      }
    };

    cancelAnimationFrame(animationRef.current);
    animationRef.current = requestAnimationFrame(animate);
  }, []);

  const changeYear = useCallback(
    (increment) => {
      setSearchInput((prev) => {
        const actualIncrement = getYearIncrement(prev.year, increment);
        let newYear =
          prev.year + (increment > 0 ? actualIncrement : -actualIncrement);

        if (newYear > 2024) {
          newYear = 2024;
        } else if (newYear < -500) { 
          newYear = -500;
        }

        animateYearChange(prev.year, newYear);
        return { ...prev, year: newYear };
      });
    },
    [getYearIncrement, animateYearChange]
  );

  const [holdStartTime, setHoldStartTime] = useState(null);

  // Add new state for tracking rapid clicks
  const [isRapidClicking, setIsRapidClicking] = useState(false);

  const handleMouseDown = useCallback(
    (increment) => {
      if (isAnimating) {
        setIsRapidClicking(true);
      }
      setIsHolding(true);
      setHoldDirection(increment);
      setHoldStartTime(Date.now());
      changeYear(increment);
    },
    [changeYear, isAnimating]
  );

  const handleMouseUp = useCallback(() => {
    setIsHolding(false);
    setHoldDirection(0);
    setSpeed(initialSpeed);
    setHoldStartTime(null);
  }, [initialSpeed]);

  // Modify the useEffect hook that handles storing and logging the selected range
  useEffect(() => {
    if (!isAnimating && !isHolding) {
      const formattedFullRange = formatFullYearRange(displayYear);
      setSelectedRange(formattedFullRange);
      // Add this line to update parent's state
      setSearchInput(prev => ({
        ...prev,
        yearRange: formattedFullRange
      }));
    }
  }, [
    isAnimating,
    isHolding,
    displayYear,
    formatFullYearRange,
  ]);

  useEffect(() => {
    if (isHolding || isAnimating) {
      // Play the audio if not already playing
      if (audioRef.current && audioRef.current.paused) {
        audioRef.current.play().catch((error) => {
          console.error("Error playing audio:", error);
        });
      }
    } else {
      // Pause the audio if it's playing
      if (audioRef.current && !audioRef.current.paused) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }
    }
  }, [isHolding, isAnimating]);

  useEffect(() => {
    // Initialize the audio
    audioRef.current = new Audio(tickTockAudio);
    audioRef.current.loop = true;
    audioRef.current.volume = 0.3;

    return () => {
      // Cleanup the audio on unmount
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current = null;
      }
    };
  }, []);

  useEffect(() => {
    if (isHolding) {
      const holdInterval = setInterval(() => {
        const holdDuration = Date.now() - holdStartTime;
        
        // Calculate new speed based on hold duration
        let newSpeed = initialSpeed;
        if (holdDuration >= 6000) { // 6 seconds
          newSpeed = initialSpeed * 0.4; // 40% faster than initial
          console.log('🚀 Speed increased to 40% faster! Current speed:', newSpeed);
        } else if (holdDuration >= 2000) { // 2 seconds
          newSpeed = initialSpeed * 0.6; // 20% faster than initial
          console.log('⚡ Speed increased to 20% faster! Current speed:', newSpeed);
        }
        
        // Update speed if it's different
        if (newSpeed !== speed) {
          setSpeed(newSpeed);
        }
        
        changeYear(holdDirection);
      }, speed);
      
      return () => clearInterval(holdInterval);
    }
  }, [isHolding, holdDirection, changeYear, speed, holdStartTime, initialSpeed]);

  useEffect(() => {
    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (isPulsing) {
      const pulseTimeout = setTimeout(() => {
        setIsPulsing(false);
      }, 300); // Duration matches the CSS animation duration

      return () => clearTimeout(pulseTimeout);
    }
  }, [isPulsing]);

  // New useEffect to handle selectedYear changes
  useEffect(() => {
    if (selectedYear && selectedYear !== displayYear) {
      animateYearChange(displayYear, selectedYear);
    }
  }, [selectedYear]);

  // Add effect to reset rapid clicking state
  useEffect(() => {
    if (!isAnimating) {
      setIsRapidClicking(false);
    }
  }, [isAnimating]);

  return (
    <div className={`${styles.yearSelector} 
        ${useAdaptiveStyle ? styles.adaptive : ''} 
        ${(isHolding || isAnimating) ? styles.isChanging : ''}
        ${isHolding ? styles.holding : ''}
        ${isRapidClicking ? styles.rapidClicking : ''}`}>
      <span
        className={`${styles.arrow} hover-hand ${!visible ? styles.disabled : ""}`}
        onMouseDown={() => handleMouseDown(-1)}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
      >
        −
      </span>
      <span
        className={`${styles.yearDisplay} ${updateFontClass()} ${
          isAnimating ? styles.yearDisplayAnimating : ""
        } ${isPulsing ? styles.pulse : ""} ${getYearClass(displayYear)}`}
      >
        {formatYearRange(displayYear)}
      </span>
      <span
        className={`${styles.arrow} hover-hand ${!visible ? styles.disabled : ""}`}
        onMouseDown={() => handleMouseDown(1)}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
      >
        +
      </span>
    </div>
  );
};

export default TimeAdjustment;
