// AppSidebar.js

import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { FiUser } from "react-icons/fi";
import "@flaticon/flaticon-uicons/css/all/all.css";
import { ReactComponent as SiteNamePlusIcon } from "../../images/sitenameplusicon.svg";
import styles from "./AppSidebar.module.css"; // Import the CSS module
import "@flaticon/flaticon-uicons/css/all/all.css";

// Context
import { VideoContext } from "../../context/videoContext";

// Import the images
const infiniteSearchImage =
  "https://hebbkx1anhila5yf.public.blob.vercel-storage.com/search-WKer4CoABTQ4vqW38xP4lnAtbHNx1z.png";
const timeMachineImage =
  "https://2i2kkpmomojld4ki.public.blob.vercel-storage.com/maybe3-iVFJqArp3TX0WNI2yetCA7SE6sIozx.png";
const homeImage =
  "https://o2pepkix6wyhtamc.public.blob.vercel-storage.com/TORSERA/images/SD3Mimage6-vjH800gh5VOvgUDZoIjwEFFDBL5w1R.webp";

// Thumbnail component
const Thumbnail = ({ src, isActive }) => (
  <div
    className={`w-8 h-8 mr-3 rounded-md overflow-hidden border ${
      isActive ? "border-purple-500" : "border-gray-600"
    }`}
  >
    <img
      src={src}
      alt=""
      className={`w-full h-full object-cover ${isActive ? "" : "opacity-93"}`}
    />
  </div>
);

const AppSidebar = () => {
  const location = useLocation();
  const { setIsVideo } = useContext(VideoContext);

  const isActive = (path) => location.pathname === path;

  const linkClass = (path) =>
    `flex items-center px-4 py-3 rounded-md transition duration-200 focus:outline-none relative ${
      isActive(path)
        ? "text-white bg-purple-600/10"
        : "text-gray-400 hover:bg-purple-600/5 hover:text-white focus:bg-purple-600/5 focus:text-white"
    }`;

  return (
    <aside
      className={`w-64 text-white h-screen fixed left-0 top-0 shadow-lg flex flex-col ${styles["sidebar-container"]}`}
    >
      <div className="p-4 mb-1 flex items-center justify-center pl-[10px] relative">
        <div className="flex items-center">
          <SiteNamePlusIcon className="h-8 w-auto" />
          <span className="ml-[5px] bg-purple-600/20 text-purple-300 text-[9px] px-1 py-0.5 rounded font-medium border border-purple-300/30 mt-[14px]">
            BETA
          </span>
        </div>
      </div>
      <hr className="border-t border-zinc-700 mx-4" />
      <nav className="flex-grow pt-4">
        <ul className="space-y-1 px-2">
          <li>
            <Link
              to="/generative-search"
              className={linkClass("/generative-search")}
              aria-current={
                isActive("/generative-search") ? "page" : undefined
              }
              onClick={() => {
                setIsVideo(false);
              }}
            >
              {isActive("/generative-search") && (
                <div className="absolute left-0 top-0 bottom-0 w-1 bg-purple-500 rounded-r-md"></div>
              )}
              <Thumbnail
                src={infiniteSearchImage}
                isActive={isActive("/generative-search")}
              />
              <span className="font-medium">Infinite Search</span>
            </Link>
          </li>
          <li>
            <Link
              to="/time-machine"
              className={linkClass("/time-machine")}
              aria-current={isActive("/time-machine") ? "page" : undefined}
              onClick={() => {
                setIsVideo(false);
              }}
            >
              {isActive("/time-machine") && (
                <div className="absolute left-0 top-0 bottom-0 w-1 bg-purple-500 rounded-r-md"></div>
              )}
              <Thumbnail
                src={timeMachineImage}
                isActive={isActive("/time-machine")}
              />
              <span className="font-medium">Time Machine</span>
            </Link>
          </li>
          <li>
            <Link
              to="/direct-mode" // Changed from "/" to "/direct-mode"
              className={linkClass("/direct-mode")}
              aria-current={isActive("/direct-mode") ? "page" : undefined}
              onClick={() => {
                setIsVideo(false);
              }}
            >
              {isActive("/direct-mode") && (
                <div className="absolute left-0 top-0 bottom-0 w-1 bg-purple-500 rounded-r-md"></div>
              )}
              <Thumbnail src={homeImage} isActive={isActive("/direct-mode")} />
              <span className="font-medium">Direct Mode</span>
            </Link>
          </li>
          <li>
            <Link
              to="/account"
              className={linkClass("/account")}
              aria-current={isActive("/account") ? "page" : undefined}
            >
              {isActive("/account") && (
                <div className="absolute left-0 top-0 bottom-0 w-1 bg-purple-500 rounded-r-md"></div>
              )}
              <FiUser className="mr-3 text-lg" />
              <span className="font-medium">Account</span>
            </Link>
          </li>
        </ul>
      </nav>
      <div className="mt-auto p-4 flex justify-center space-x-5">
        <i className="fi fi-brands-discord text-gray-400/50 hover:text-white/90 transition-colors duration-200 text-lg cursor-pointer"></i>
        <i className="fi fi-brands-tik-tok text-gray-400/50 hover:text-white/90 transition-colors duration-200 text-lg cursor-pointer"></i>
        <i className="fi fi-brands-reddit text-gray-400/50 hover:text-white/90 transition-colors duration-200 text-lg cursor-pointer"></i>
        <i className="fi fi-brands-twitter text-gray-400/50 hover:text-white/90 transition-colors duration-200 text-lg cursor-pointer"></i>
      </div>
    </aside>
  );
};

export default AppSidebar;
