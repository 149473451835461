import { createContext, useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import { onAuthStateChanged } from "firebase/auth";
import "firebase/compat/firestore";
import axios from "axios";
import { LoadingSpinner } from "../components/LoadingSpinner/LoadingSpinner";

export const AppContext = createContext({});

export const AppProvider = (props) => {
  const { children } = props;
  const [alertOptions, setAlertOptions] = useState({
    isOpen: false,
    text: "",
    status: "success",
  });
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    const storedAuth = localStorage.getItem("isAuthenticated");
    return storedAuth ? JSON.parse(storedAuth) : false;
  });
  const [isAppLoading, setIsAppLoading] = useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isPricingModalOpen, setIsPricingModalOpen] = useState(false);
  const [pricingOptions, setPricingOptions] = useState([]);
  const [retryCount, setRetryCount] = useState(0);
  const [userData, setUserData] = useState(() => {
    // Initialize userData from localStorage if available
    const savedUserData = localStorage.getItem("userData");

    if (savedUserData) {
      try {
        return JSON.parse(savedUserData);
      } catch (error) {
        console.error("Error parsing userData from localStorage:", error);
        // If there's an error parsing, remove the invalid data
        localStorage.removeItem("userData");
      }
    }
    return null;
  });

  const auth = firebase.auth();

  const handleAuthStateChange = async (user) => {
    if (user) {
      const initial = user.displayName?.charAt(0)?.toUpperCase() || "";
      const newUserData = {
        ...user,
        displayName: user.displayName,
        initial: initial,
      };
      try {
        const updatedUserData = await loginUser(user, newUserData);
        setUserData(updatedUserData);
        setIsAuthenticated(true);
        localStorage.setItem("isAuthenticated", JSON.stringify(true));
        localStorage.setItem("userData", JSON.stringify(updatedUserData));
        getProducts();
      } catch (error) {
        console.error("Error during session login:", error);
        localStorage.setItem("isAuthenticated", JSON.stringify(false));
      }
    } else {
      window.history.pushState({}, "", "/");
      const navEvent = new PopStateEvent("popstate");
      window.dispatchEvent(navEvent);
      setIsAuthenticated(false);
      localStorage.setItem("isAuthenticated", JSON.stringify(false));
      setUserData(null);
      localStorage.removeItem("userData");
    }
    setIsAppLoading(false);
  };

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  async function loginUser(user, newUserData) {
    let retryCount = 0;
    const maxRetries = 1;

    while (retryCount <= maxRetries) {
      try {
        const idToken = await user.getIdToken(true);
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/session_login`,
          { idToken },
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        );
        const data = response.data;
        return {
          ...newUserData,
          email: data.user_data?.email || null,
          credits: data.user_data?.credits || 0,
          providerId: user.providerData?.providerId || null,
          stripe_customer_id: data.user_data?.stripe_customer_id || null,
          subscription_tier:
            data.user_data?.current_subscription_tier || "free",
          user_id: data.user_data?.user_id || null,
          ...data,
        };
      } catch (error) {
        console.error("Login attempt failed:", error);
        if (retryCount < maxRetries) {
          await delay(1000); // Wait for 1 second before retrying
          retryCount++;
        } else {
          throw error; // If all retries fail, throw the error to be handled by the caller
        }
      }
    }
  }

  useEffect(() => {
    setIsAppLoading(true);
    auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);

    const unsubscribe = onAuthStateChanged(auth, handleAuthStateChange);

    return () => unsubscribe();
  }, [auth]);

  async function getProducts() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/get_products`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      setPricingOptions(response.data.products);
    } catch (error) {
      console.error("Error fetching products:", error);
      // Handle the error appropriately, e.g., set an error state or show a notification
    }
  }

  const appContext = {
    alertOptions,
    auth,
    getProducts,
    handleAuthStateChange,
    isAppLoading,
    isAuthenticated,
    isLoginModalOpen,
    isPricingModalOpen,
    pricingOptions,
    setAlertOptions,
    setIsLoginModalOpen,
    setIsPricingModalOpen,
    setUserData,
    userData,
  };

  if (isAppLoading) {
    return <LoadingSpinner />;
  }

  return (
    <AppContext.Provider value={appContext}>{children}</AppContext.Provider>
  );
};
