import React, { useState } from "react";
import { FiCreditCard, FiArrowUp, FiDollarSign } from "react-icons/fi";
import { motion } from "framer-motion";
import { handleUpdateBillingDetails } from "../../firebaseutils";
import { LoadingDots } from "../../components/LoadingDots/LoadingDots";

const SubscriptionManagement = ({ userData, onOpenPricingModal }) => {
  const isSubscribed = userData?.subscription_tier !== "free";

  const [isButtonLoading, setIsButtonLoading] = useState(false);

  return (
    <motion.div
      className="bg-gradient-to-br from-zinc-800 to-zinc-900 p-6 rounded-lg shadow-lg flex flex-col h-full transition-all duration-300 hover:shadow-xl"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay: 0.2 }}
      style={{ backgroundColor: "#101010" }}
    >
      <h2 className="text-2xl font-semibold text-zinc-100 mb-6 flex items-center">
        <FiCreditCard className="w-6 h-6 mr-3 text-purple-400" />
        Manage your subscription
      </h2>
      <div className="bg-[#27272A] rounded-lg p-4 mb-4">
        <div className="flex items-center mb-2">
          <span className="text-zinc-300 font-medium pr-2">Current Tier:</span>
          <span className="font-semibold text-purple-400 text-lg tracking-wide">
            {userData?.subscription_tier?.toUpperCase()}
          </span>
        </div>
      </div>
      <ActionButton
        icon={FiArrowUp}
        text={isSubscribed ? "Manage Plan" : "Subscribe"}
        primary={true}
        onClick={onOpenPricingModal}
        userData={{ stripe_customer_id: true }}
      />
      <ActionButton
        icon={FiDollarSign}
        text="Update Billing Details"
        primary={false}
        onClick={() => {
          handleUpdateBillingDetails(userData);
          setIsButtonLoading(true);
        }}
        userData={userData}
        isButtonLoading={isButtonLoading}
      />
    </motion.div>
  );
};

const ActionButton = ({
  icon: Icon,
  text,
  primary,
  onClick,
  userData,
  isButtonLoading,
}) => (
  <motion.button
    whileHover={{
      scale: 1.02,
      boxShadow: `0 0 15px ${primary ? "rgba(167, 139, 250, 0.5)" : "rgba(82, 82, 91, 0.5)"}`,
    }}
    whileTap={{ scale: 0.98 }}
    className={`${primary ? "bg-purple-600 hover:bg-purple-700" : "bg-[#27272A] hover:bg-zinc-700"} text-white px-4 py-2 rounded-lg transition-all duration-300 flex items-center justify-center mb-2 shadow-md font-medium tracking-wide`}
    onClick={onClick}
    disabled={!userData?.stripe_customer_id}
  >
    {isButtonLoading ? (
      <LoadingDots />
    ) : (
      <>
        <Icon className="w-4 h-4 mr-2" />
        {text}
      </>
    )}
  </motion.button>
);

export default SubscriptionManagement;
