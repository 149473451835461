import React, { useEffect, useState, useRef } from 'react';
import { trio } from 'ldrs';
import styles from './GlobeSpinner.module.css';

trio.register();

const GlobeSpinner = ({ isVisible, onAnimationComplete }) => {
  const [opacity, setOpacity] = useState(0);
  const timeoutRef = useRef(null);
  const fadeOutTimerRef = useRef(null);

  useEffect(() => {
    if (isVisible) {
      // Clear any existing timers
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
      if (fadeOutTimerRef.current) clearTimeout(fadeOutTimerRef.current);

      // Start fade in
      timeoutRef.current = setTimeout(() => setOpacity(0.4), 50);

      // Set up automatic fade out after fixed duration (1.6s)
      fadeOutTimerRef.current = setTimeout(() => {
        setOpacity(0);
        setTimeout(onAnimationComplete, 650); // Wait for fade out to complete
      }, 1600); // 1.6s total visibility time
    } else {
      // Movement completed before fixed duration
      setOpacity(0);
      timeoutRef.current = setTimeout(onAnimationComplete, 650);
    }

    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
      if (fadeOutTimerRef.current) clearTimeout(fadeOutTimerRef.current);
    };
  }, [isVisible, onAnimationComplete]);

  if (!isVisible && opacity === 0) return null;

  return (
    <div className={styles.spinnerContainer} style={{ opacity }}>
      <l-trio
        size="45"
        speed="1.4"
        color="white"
      ></l-trio>
    </div>
  );
};

export default GlobeSpinner;
