import React from "react";

const FeatureCard = ({
  title,
  description,
  image,
  isNew = false,
  imagePadding = "",
  iconClass,
  variant = "default",
}) => {
  const variantStyles = {
    search: "border border-blue-500/20 hover:shadow-lg hover:shadow-blue-500/20",
    timeMachine: "border border-teal-500/20 hover:shadow-lg hover:shadow-teal-500/20",
    direct: "border border-purple-600/20 hover:shadow-lg hover:shadow-purple-600/20",
    default: "hover:shadow-lg hover:shadow-purple-800/10",
  };

  return (
    <div className={`group bg-zinc-900 rounded-xl overflow-hidden transition-all duration-300 ease-in-out ${variantStyles[variant]} w-full mx-auto`}
         style={{
           boxShadow: variant !== 'default' ? '0 4px 12px -2px rgba(0, 0, 0, 0.3)' : 'none'
         }}>
      <div className={`relative aspect-[16/9] overflow-hidden ${imagePadding}`}>
        <div className="w-full h-full overflow-hidden rounded-lg">
          <img
            src={image}
            alt={title}
            className="w-full h-full object-cover"
          />
        </div>
        <div className="absolute inset-0 bg-gradient-to-t from-zinc-900 to-transparent opacity-30"></div>
      </div>
      <div className="p-3 sm:p-4 pt-2 pb-4 sm:pb-5 -mt-2 transition-all duration-300 ease-in-out">
        <div className="flex items-center mb-1">
          <h3 className="text-lg sm:text-xl font-bold text-zinc-100 mr-2 transition-colors duration-300">
            {title}
          </h3>
          {isNew && (
            <span className="bg-purple-800 text-zinc-100 text-xs font-semibold px-2 py-1 rounded-full transition-all duration-300 group-hover:bg-purple-700">
              New
            </span>
          )}
        </div>
        <p className="text-zinc-400 text-xs sm:text-sm transition-colors duration-300 group-hover:text-zinc-300">
          {description}
        </p>
      </div>
    </div>
  );
};

export default FeatureCard;
