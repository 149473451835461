import React, { useEffect, useState } from "react";
import style from "./generative-search-cards.module.css";
import { quantum } from "ldrs";

const SkeletonCard = () => {
  const [elapsedTime, setElapsedTime] = useState(0);

  useEffect(() => {
    quantum.register();

    const timer = setInterval(() => {
      setElapsedTime((prevTime) => prevTime + 10); // Increment by 10ms
    }, 10); // Update every 10ms

    return () => clearInterval(timer);
  }, []);

  const formatTime = (time) => {
    const seconds = Math.floor(time / 1000);
    const milliseconds = time % 1000;
    return `${seconds}.${milliseconds.toString().padStart(3, "0").slice(0, 2)}s`;
  };

  return (
    <div
      className={`${style["skeleton-card"]} rounded-lg overflow-hidden shadow-lg transition-all duration-300 ease-in-out`}
    >
      <div className={`${style["loading-animation"]}`}></div>
      <div className={style["quantum-loader-container"]}>
        <l-quantum size="70" speed="4" color="rgb(93, 199, 234)"></l-quantum>
      </div>
      <div className={style["elapsed-time"]}>{formatTime(elapsedTime)}</div>
    </div>
  );
};

export default SkeletonCard;
