import mapboxgl from 'mapbox-gl';
import globeMovementAudio from '../../audio/globemoveabc.mp3';

// Initialize audio with the imported file
const globeMovementSound = new Audio(globeMovementAudio);
globeMovementSound.volume = 0.3;

// Add error handling for audio
globeMovementSound.addEventListener('error', (e) => {
    console.error('Error loading or playing audio:', e);
});

// Custom easing function
const quadraticEasingOut = (t) => {
    return t * (2 - t);
};

/**
 * Calculates the great-circle distance between two points using the Haversine formula.
 * @param {number} lng1 - Longitude of the first point.
 * @param {number} lat1 - Latitude of the first point.
 * @param {number} lng2 - Longitude of the second point.
 * @param {number} lat2 - Latitude of the second point.
 * @returns {number} - Distance in kilometers.
 */
const getDistanceInKilometers = (lng1, lat1, lng2, lat2) => {
    const toRadians = (degrees) => degrees * (Math.PI / 180);
    const R = 6371; // Earth's radius in kilometers

    const dLat = toRadians(lat2 - lat1);
    const dLng = toRadians(lng2 - lng1);

    const a =
        Math.sin(dLat / 2) ** 2 +
        Math.cos(toRadians(lat1)) *
            Math.cos(toRadians(lat2)) *
            Math.sin(dLng / 2) ** 2;

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return R * c;
};

/**
 * Move the globe to the chosen country and simulate a click with dynamic duration based on distance.
 * @param {Object} map - The Mapbox map object.
 * @param {string} countryName - The name of the chosen country.
 * @param {Object} currentMarkerRef - Reference to the current marker.
 * @param {function} updateUI - Callback function to update the UI.
 * @param {function} onMoveStart - Callback function to be called at the beginning of the function.
 * @param {function} onMoveComplete - Callback function to be called after the movement is complete.
 */
export const moveGlobeToCountry = (map, countryName, currentMarkerRef, updateUI, onMoveStart, onMoveComplete) => {
    // Play the sound when movement starts
    globeMovementSound.currentTime = 0; // Reset audio to start
    globeMovementSound.play();
    
    onMoveStart();

    const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
        countryName
    )}.json?access_token=${mapboxgl.accessToken}&limit=1`;

    fetch(url)
        .then((response) => response.json())
        .then((data) => {
            if (data.features && data.features.length > 0) {
                const country = data.features[0];

                // Use the 'center' provided by the API
                const [centerLng, centerLat] = country.center;

                // Get the current center of the map
                const currentCenter = map.getCenter();
                const currentLng = currentCenter.lng;
                const currentLat = currentCenter.lat;

                // Calculate the distance between current center and target center
                const distance = getDistanceInKilometers(
                    currentLng,
                    currentLat,
                    centerLng,
                    centerLat
                );

                const rotationSpeed = 1;

                // Calculate dynamic duration based on distance
                let duration = distance / rotationSpeed;

                // Define minimum and maximum duration limits (in milliseconds)
                const minDuration = 1000; // 1 second
                const maxDuration = 8000; // 8 seconds

                // Clamp the duration within the min and max limits
                duration = Math.max(minDuration, Math.min(maxDuration, duration));

                // Log the duration to the console
                console.log(`Travel time to ${country.text}: ${(duration / 1000).toFixed(2)} seconds`);

                // Fly the map to the country's center with dynamic duration and custom easing
                map.flyTo({
                    center: [centerLng, centerLat],
                    zoom: 4, // Adjust zoom level as needed
                    duration: duration, // Dynamic fly duration in milliseconds
                    easing: quadraticEasingOut, // Custom easing function
                    essential: true,
                });

                console.log(
                    `Moving globe to ${country.text} with duration ${duration} ms`
                );

                // Set up timeout to match the flyTo duration
                setTimeout(() => {
                    if (currentMarkerRef.current) {
                        currentMarkerRef.current.remove();
                    }

                    // Place a marker at the country's center
                    currentMarkerRef.current = new mapboxgl.Marker({ color: 'red' })
                        .setLngLat([centerLng, centerLat])
                        .addTo(map);

                    // Simulate the map click event with a complete event object
                    map.fire('click', {
                        lngLat: { lng: centerLng, lat: centerLat },
                        point: map.project([centerLng, centerLat]),
                        target: map.getCanvas(),
                        originalEvent: {
                            clientX: 0,
                            clientY: 0,
                            target: map.getCanvas(),
                        },
                    });

                    updateUI(country.text);
                    if (onMoveComplete) {
                        onMoveComplete(); // Call onMoveComplete after the movement is done
                    }
                }, duration); // This delay matches the flyTo animation duration
            } else {
                console.log('Country not found');
            }
        })
        .catch((error) => console.error('Error:', error));
};
