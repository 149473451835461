import React, { useState, useEffect } from 'react';
import styles from './tmExamples.module.css';

const TmExamples = () => {
  const [isCursorNear, setIsCursorNear] = useState(false);

  // Define the radius in pixels
  const RADIUS = 50;

  // Position of the dictation button (as per tmAssistant.module.css)
  const dictationButtonPosition = {
    bottom: 100, // in px
    right: 156,  // in px
    size: 78.645 // width and height in px
  };

  useEffect(() => {
    const handleMouseMove = (event) => {
      const { innerWidth, innerHeight } = window;
      const cursorX = event.clientX;
      const cursorY = event.clientY;

      // Calculate the center position of the dictation button
      const centerX = innerWidth - dictationButtonPosition.right - (dictationButtonPosition.size / 2);
      const centerY = innerHeight - dictationButtonPosition.bottom - (dictationButtonPosition.size / 2);

      // Calculate distance between cursor and center of dictation button
      const distance = Math.sqrt(
        Math.pow(cursorX - centerX, 2) +
        Math.pow(cursorY - centerY, 2)
      );

      if (distance <= RADIUS) {
        setIsCursorNear(true);
      } else {
        setIsCursorNear(false);
      }
    };

    window.addEventListener('mousemove', handleMouseMove);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <>
      {isCursorNear && (
        <div className={styles.exampleBox}>
          <span className={styles.thickerText}> "Iraq 1275"</span>
        </div>
      )}
    </>
  );
};

export default TmExamples;
