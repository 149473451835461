import React, { useEffect, useRef, useState } from "react";
import style from "./drop-down-selector.module.css";
import "@flaticon/flaticon-uicons/css/all/all.css";

const presetIcons = {
  Torsera: (
    <i className="fi fi-br-crystal-ball" style={{ color: "#F1C40F" }}></i>
  ),
  Horror: (
    <i className="fi fi-br-spider-black-widow" style={{ color: "#E67E22" }}></i>
  ),
  "Mini-Doc": (
    <i className="fi fi-br-video-camera" style={{ color: "#3498DB" }}></i>
  ),
  Unhinged: <i className="fi fi-br-bomb" style={{ color: "#E74C3C" }}></i>,
  Bizarre: <i className="fi fi-br-galaxy-alt" style={{ color: "#8E44AD" }}></i>,
  Adventure: <i className="fi fi-br-map" style={{ color: "#27AE60" }}></i>,
  "Thought-provoking": (
    <i className="fi fi-br-thought-bubble" style={{ color: "#1ABC9C" }}></i>
  ),
  "Black-Mirror": (
    <i className="fi fi-br-user-robot" style={{ color: "#95A5A6" }}></i>
  ),
  "Create Your Own": (
    <i className="fi fi-br-hammer" style={{ color: "#D35400" }}></i>
  ),
  "Alan Watts": (
    <i className="fi fi-br-meditation" style={{ color: "#16A085" }}></i>
  ),
  "World Simulator": "🌍",
  Therapy: "💆",
  "Buddhist Monk": (
    <i className="fi fi-br-dharmachakra" style={{ color: "#F39C12" }}></i>
  ),
  Greentext: "📗",
};

const truncateText = (text, maxLength) => {
  if (text.length <= maxLength) return text;
  return text.slice(0, maxLength - 1) + "…";
};

const dropdownIcons = {
  style: <i className="fi fi-rr-picture" style={{ color: "#F1C40F" }}></i>,
  mode: <i className="fi fi-rr-apps" style={{ color: "#3498DB" }}></i>,
  voice: <i className="fi fi-rr-volume" style={{ color: "#4CAF50" }}></i>,
};

export const DropDownSelector = ({
  gallery = false,
  label,
  visualLabel,
  onClickFunction,
  selectList,
  text,
  type,
}) => {
  const [open, setOpen] = useState(false);
  const [glowEffect, setGlowEffect] = useState(false);
  const [loadedImages, setLoadedImages] = useState({});
  const buttonRef = useRef(null);

  useEffect(() => {
    if (gallery) {
      selectList.forEach((item) => {
        const img = new Image();
        img.onload = () =>
          setLoadedImages((prev) => ({ ...prev, [item.name]: true }));
        img.src = item.img;
      });
    }
  }, [gallery, selectList]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (buttonRef.current && !buttonRef.current.contains(event.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [buttonRef]);

  const handleOpen = () => setOpen(!open);

  const displayText = truncateText(text, 20);

  const isOptionDisabled = (itemName) => {
    return (
      (type === "mode" && itemName === "Create Your Own") ||
      (type === "length" && itemName !== "Auto")
    );
  };

  const handleOptionClick = (itemName) => {
    if (!isOptionDisabled(itemName)) {
      setOpen(false);
      onClickFunction(itemName, type);
      setGlowEffect(true);
      setTimeout(() => setGlowEffect(false), 500);
    }
  };

  return (
    <div
      className={`${style["dropdown-container"]} ${style["mobile-dropdown"]}`}
    >
      <button
        ref={buttonRef}
        type="button"
        className={`${style["dropdown-button"]} ${open ? style["open"] : ""} ${glowEffect ? style["glow-effect"] : ""} hover-hand`}
        onClick={handleOpen}
        aria-haspopup="listbox"
        aria-expanded={open}
      >
        {dropdownIcons[type] && (
          <span className={style["dropdown-icon"]} aria-hidden="true">
            {dropdownIcons[type]}
          </span>
        )}
        <span className={style["dropdown-label"]}>{visualLabel || label}</span>
        <span className={style["button-text"]}>{displayText}</span>
        <span className={style["dropdown-arrow"]} aria-hidden="true"></span>
      </button>
      {open && (
        <ul
          className={`${style["dropdown-options"]} ${gallery ? style.gallery : ""} ${style.fadeIn} hover-hand`}
          id={`${type}-options`}
          role="listbox"
          aria-label={`${visualLabel || label} options`}
        >
          {selectList?.map((item, index) => {
            const icon = type === "mode" ? presetIcons[item.name] : null;
            const isDisabled = isOptionDisabled(item.name);
            return (
              <li
                onClick={() => !isDisabled && handleOptionClick(item.name)}
                key={index}
                className={`${style["dropdown-item"]} ${isDisabled ? style["disabled-option"] : ""} hover-hand`}
                role="option"
                aria-selected={item.name === text}
                tabIndex={isDisabled ? -1 : 0}
              >
                <span className={gallery ? style["gallery-item-label"] : ""}>
                  {icon && (
                    <span className={style["preset-icon"]} aria-hidden="true">
                      {typeof icon === "string" ? icon : icon}
                    </span>
                  )}
                  <span className={style["preset-text"]}>{item.name}</span>
                </span>
                {gallery &&
                  (loadedImages[item.name] ? (
                    <img
                      src={item.img}
                      alt={`${item.name} style example`}
                      className={style["gallery-image"]}
                    />
                  ) : (
                    <div
                      className={style["image-placeholder"]}
                      aria-label="Loading image"
                    >
                      Loading...
                    </div>
                  ))}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};
