import React, { useState, useEffect, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import placeholderImage from "../../images/placeholder.jpg";
import GenLoadingState from "./GenLoadingState";
import styles from "../PromptInputContainer/PromptInputContainer.module.css";

import { getAnalytics, logEvent } from "firebase/analytics";
import PreviewRateLimit from "./PreviewRateLimit";

const PreviewCard = ({
  imageUrl,
  isLoading,
  prompt,
  error,
  className,
  incrementCount,
  isRateLimited,
}) => {
  const analytics = getAnalytics();
  const [currentImageUrl, setCurrentImageUrl] = useState(placeholderImage);
  const [previousImageUrl, setPreviousImageUrl] = useState(placeholderImage);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const currentImageRef = useRef(null);
  const previousImageRef = useRef(null);
  const [isPlaceholder, setIsPlaceholder] = useState(true);

  const transitionStyles = {
    transition:
      "opacity 300ms ease-in-out, transform 300ms ease-in-out, filter 300ms ease-in-out",
  };

  useEffect(() => {
    if (imageUrl && imageUrl !== currentImageUrl && !isRateLimited) {
      setPreviousImageUrl(currentImageUrl);
      setCurrentImageUrl(imageUrl);
      setIsTransitioning(true);
      setIsPlaceholder(false);
      if (incrementCount) {
        incrementCount();
      }
    }
  }, [imageUrl, incrementCount, isRateLimited]);

  const handleImageLoad = () => {
    if (currentImageRef.current) {
      currentImageRef.current.style.opacity = "1";
      currentImageRef.current.style.transform = "scale(1)";
      currentImageRef.current.style.filter = "blur(0)";
    }
    if (previousImageRef.current) {
      previousImageRef.current.style.opacity = "0";
      previousImageRef.current.style.transform = "scale(0.95)";
      previousImageRef.current.style.filter = "blur(5px)";
    }
    setTimeout(() => {
      setIsTransitioning(false);
    }, 300);
  };

  const handleExpand = () => {
    setIsExpanded(true);
    logEvent(analytics, `button_click_expand_preview`);
  };

  const handleCollapse = (e) => {
    if (e.target === e.currentTarget) {
      setIsExpanded(false);
    }
  };

  return (
    <div className={`relative ${className}`}>
      <motion.div
        className={`w-[96px] h-[72px] aspect-[16/10] overflow-hidden rounded-xl shadow-md border border-[rgba(78,78,78,0.9)] bg-[rgba(255,255,255,0.8)] cursor-pointer transition-all duration-300 ease-in-out backface-hidden transform-gpu
          xl:w-[131.09px] xl:h-[81.9315px]
          2xl:w-[174.787px] 2xl:h-[109.242px] -mr-1 mb-1 ${className}`}
        onClick={handleExpand}
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.98 }}
        style={{ opacity: isPlaceholder ? 0.88 : 1 }}
      >
        <GenLoadingState isLoading={isLoading} />
        <div className="relative w-full h-full z-20">
          <img
            ref={previousImageRef}
            src={previousImageUrl}
            alt="Previous Preview"
            className="absolute w-full h-full object-cover rounded-xl"
            style={{
              ...transitionStyles,
              opacity: isTransitioning ? 1 : 0,
              transform: isTransitioning ? "scale(1.02)" : "scale(1)",
              filter: isTransitioning ? "blur(3px)" : "blur(0)",
            }}
          />
          <img
            ref={currentImageRef}
            src={currentImageUrl}
            alt="Current Preview"
            className="absolute w-full h-full object-cover rounded-xl"
            style={{
              ...transitionStyles,
              opacity: isTransitioning ? 0.5 : 1,
              transform: isTransitioning ? "scale(1.05)" : "scale(1)",
              filter: isTransitioning ? "blur(3px)" : "blur(0)",
            }}
            onLoad={handleImageLoad}
          />
          {isLoading && (
            <div className="absolute inset-0 bg-black bg-opacity-30 rounded-xl z-10 transition-opacity duration-300 ease-in-out"></div>
          )}
          <div className="absolute bottom-[6.42px] right-[6.42px] bg-[rgba(0,0,0,0.6)] text-[rgb(236,236,236)] px-[6.60px] py-[3.30px] rounded-[6.76px] text-[0.705em] z-20">
            Thumbnail
          </div>
          {error && (
            <div className="absolute inset-0 flex items-center justify-center text-red-500">
              {error}
            </div>
          )}
        </div>
      </motion.div>
      <AnimatePresence>
        {isExpanded && (
          <motion.div
            className="fixed inset-0 bg-black bg-opacity-80 flex justify-center items-center z-[1000] p-5"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={handleCollapse}
          >
            <motion.div
              className="max-w-[90vmin] max-h-[90vmin] w-[90vmin] h-[90vmin] relative flex justify-center items-center"
              initial={{ scale: 0.8, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.8, opacity: 0 }}
              transition={{ type: "spring", stiffness: 300, damping: 30 }}
              onClick={(e) => e.stopPropagation()}
            >
              <div className="w-full h-full overflow-hidden flex justify-center items-center rounded-xl">
                <img
                  src={currentImageUrl}
                  alt="Expanded Preview"
                  className="w-full h-full object-cover rounded-xl"
                />
              </div>
              <div className="absolute bottom-0 left-0 right-0 p-5 bg-gradient-to-t from-black to-transparent text-white text-center rounded-b-xl">
                <p className="m-0 p-0 font-light leading-relaxed">{prompt}</p>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const PreviewCardWithRateLimit = (props) => (
  <PreviewRateLimit>
    <PreviewCard {...props} />
  </PreviewRateLimit>
);

export default PreviewCardWithRateLimit;