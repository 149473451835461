import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/App.css";
// Context
import { AppProvider } from "./context/appContext";
import { VideoProvider } from "./context/videoContext";

// Router
import { AppRoutes } from "./AppRoutes";

import "non.geist";
import "non.geist/mono";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AppProvider>
      <VideoProvider>
        <AppRoutes />
      </VideoProvider>
    </AppProvider>
  </React.StrictMode>
);
