import React, { useEffect, useRef, useState } from "react";

interface BorderGlowButtonProps {
  onClick: () => void;
}

const BorderGlowButton: React.FC<BorderGlowButtonProps> = ({ onClick }) => {
  const ref = useRef<HTMLButtonElement>(null);
  const [mousePosition, setMousePosition] = useState({
    x: "-100%",
    y: "-100%",
  });

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      if (!ref.current) return;
      const rect = ref.current.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;
      setMousePosition({ x: `${x}px`, y: `${y}px` });
    };
    document.addEventListener("mousemove", handleMouseMove);
    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return (
    <button
      className="relative overflow-hidden rounded-full bg-transparent border border-white/30 px-6 py-2 min-w-[100px] transform transition-all duration-300 ease-in-out hover:bg-[#845dba]/10 active:scale-95"
      ref={ref}
      onClick={onClick}
    >
      <span
        className="absolute z-0 h-24 w-24 -translate-x-1/2 -translate-y-1/2 bg-[radial-gradient(#845dba_0%,transparent_70%)] opacity-70"
        style={{
          left: mousePosition.x,
          top: mousePosition.y,
        }}
      ></span>
      <div className="relative z-10 text-sm font-medium text-white">
        Pricing
      </div>
    </button>
  );
};

export default BorderGlowButton;
