import React from "react";

import "@flaticon/flaticon-uicons/css/all/all.css";

const SubmitGeneration = ({
  handleSubmit,
  ideaInputText,
  dropDownOptions,
  setAlertOptions,
  setIdeaInputText,
  setUserData,
}) => {
  return (
    <button
      type="submit"
      disabled={ideaInputText.length < 1}
      className="group/button relative inline-flex items-center justify-center overflow-hidden rounded-md bg-[#6425a3]/75 px-4 py-2.5 text-base font-bold text-white transition-all duration-300 ease-in-out hover:bg-[#4a1a7a]/90 hover:scale-102 hover:shadow-lg hover:shadow-[#6425a3]/30 w-full mt-2.5 border-2 border-[#6425a3] disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:bg-[#6425a3]/75 disabled:hover:scale-100 disabled:hover:shadow-none disabled:border-opacity-50"
      onClick={() =>
        handleSubmit(
          "direct mode",
          ideaInputText,
          dropDownOptions,
          setAlertOptions,
          setIdeaInputText,
          setUserData
        )
      }
    >
      <i className="fi fi-rr-sparkles mr-2 text-lg translate-y-[2px]"></i>
      <span className="text-base">Generate</span>
      <div className="absolute inset-0 flex h-full w-full justify-center [transform:skew(-13deg)_translateX(-100%)] group-hover/button:duration-1000 group-hover/button:[transform:skew(-13deg)_translateX(100%)]">
        <div className="relative h-full w-8 bg-white/20" />
      </div>
    </button>
  );
};

export default SubmitGeneration;
