import React from "react";
import { ChevronDownIcon } from "@radix-ui/react-icons";
import { motion } from "framer-motion";

const ScrollIndicator = () => (
  <motion.div
    className="fixed bottom-9 left-[calc(50%-19.5px)] transform -translate-x-1/2 z-50"
    initial={{ opacity: 0, y: 10 }}
    animate={{ opacity: 1, y: 0 }}
    exit={{ opacity: 0, y: 10 }}
    transition={{ duration: 0.3 }}
  >
    <div className="bg-white bg-opacity-50 rounded-full p-2">
      <ChevronDownIcon className="w-6 h-6 text-gray-800" />
    </div>
  </motion.div>
);

export default ScrollIndicator;