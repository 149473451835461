import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import { AppContext } from "../../context/appContext";
import AccountInfo from "./AccountInfo";
import SubscriptionManagement from "./SubscriptionManagement";
import {
  motion,
  useAnimation,
  // Removed the following line:
  // useMotionValue,
  // useTransform,
} from "framer-motion";
import { FiUser, FiCreditCard, FiLogOut } from "react-icons/fi";
import styles from "./account-home.module.css";
import { getAnalytics, logEvent } from "firebase/analytics";

const customEasing = [0.6, -0.05, 0.01, 0.99];

// Analytics

const fadeInUp = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  transition: { duration: 0.6, ease: customEasing },
};

// const Background = () => {
//   return (
//     <motion.div
//       className="fixed inset-0 z-[-1]"
//       initial={{ opacity: 0 }}
//       animate={{ opacity: 1 }}
//       transition={{ duration: 1 }}
//     >
//       <motion.div
//         className="absolute inset-0 bg-gradient-to-br from-purple-900 to-black"
//         animate={{
//           background: [
//             "linear-gradient(to bottom right, #4a0e4e, #000000)",
//             "linear-gradient(to bottom right, #3b1053, #000000)",
//             "linear-gradient(to bottom right, #4a0e4e, #000000)",
//           ],
//         }}
//         transition={{ duration: 10, repeat: Infinity, repeatType: "reverse" }}
//       />
//     </motion.div>
//   );
// };

const useInView = (options = {}) => {
  const [ref, setRef] = useState(null);
  const [inView, setInView] = useState(false);

  useEffect(() => {
    if (!ref) return;

    const observer = new IntersectionObserver(([entry]) => {
      setInView(entry.isIntersecting);
    }, options);

    observer.observe(ref);

    return () => {
      if (ref) {
        observer.unobserve(ref);
      }
    };
  }, [ref, options]);

  return [setRef, inView];
};

export const AccountHome = () => {
  const { auth, setIsPricingModalOpen, setUserData, userData } =
    useContext(AppContext);
  const navigate = useNavigate();
  const analytics = getAnalytics();

  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  useEffect(() => {
    if (!auth.currentUser) {
      navigate("/", { replace: true });
    }
  }, [auth, navigate, userData]);

  const handleLogout = async () => {
    logEvent(analytics, `button_click_logout`);
    try {
      await signOut(auth);
      setUserData(null);
      navigate("/", { replace: true });
    } catch (err) {
      console.error("Logout error:", err.message);
    }
  };

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={{
        visible: {
          transition: {
            staggerChildren: 0.1,
          },
        },
      }}
      className={`min-h-screen bg-[#101010] text-zinc-100 py-12 px-4 sm:px-6 lg:px-8 ${styles.accountPage}`}
    >
      {/* <Background /> */}
      <div className="max-w-4xl mx-auto">
        <motion.nav variants={fadeInUp} className="text-sm mb-8">
          <motion.button
            onClick={() => navigate("/")}
            className="text-purple-400 hover:text-purple-300 font-medium"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            Home
          </motion.button>{" "}
          / <span className="font-light">Manage Account</span>
        </motion.nav>

        <motion.h1
          variants={fadeInUp}
          className="text-4xl font-medium text-left mb-12 tracking-tight"
        >
          Manage Account
        </motion.h1>

        <motion.div
          variants={fadeInUp}
          className="bg-zinc-800 rounded-lg p-8 mb-8 flex items-center justify-center"
          whileHover={{ scale: 1.02 }}
          transition={{ type: "spring", stiffness: 300, damping: 10 }}
        >
          <motion.div
            className={`bg-zinc-700 rounded-full p-4 mr-6 ${styles.userIcon}`}
            whileHover={{ rotate: 360 }}
            transition={{ duration: 0.6 }}
          >
            <FiUser className="w-16 h-16 text-purple-400" />
          </motion.div>
          <div>
            <motion.p
              className="text-2xl font-semibold tracking-wide"
              variants={fadeInUp}
            >
              {userData?.email}
            </motion.p>
            <motion.p
              className="text-lg text-purple-400 flex items-center mt-2 font-medium"
              variants={fadeInUp}
            >
              <FiCreditCard className="w-5 h-5 mr-2" />
              Credits: {userData?.credits}
            </motion.p>
          </div>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12">
          <motion.div variants={fadeInUp}>
            <AccountInfo userData={userData} />
          </motion.div>
          <motion.div variants={fadeInUp}>
            <SubscriptionManagement
              userData={userData}
              onOpenPricingModal={() => {
                setIsPricingModalOpen(true);
                logEvent(
                  analytics,
                  `button_click_open_pricing_modal_account_home`
                );
              }}
            />
          </motion.div>
        </div>

        <motion.div variants={fadeInUp} className="text-center">
          <motion.button
            onClick={handleLogout}
            className="bg-red-600 text-white px-6 py-3 rounded-lg hover:bg-red-700 transition-colors duration-300 flex items-center mx-auto font-medium"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            transition={{ type: "spring", stiffness: 400, damping: 10 }}
          >
            <FiLogOut className="w-5 h-5 mr-2" />
            Logout
          </motion.button>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default AccountHome;
