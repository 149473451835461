import React, { useState, useRef, useEffect, useCallback } from "react";
import * as fuzzball from "fuzzball";
import styles from "./tmAssistant.module.css";
import { ReactComponent as MicrophoneIcon } from "../../../images/dictationoutline.svg";
import countries from "./countries.json";
import { jellyTriangle } from 'ldrs';

// Register the loader component
jellyTriangle.register();

const TmAssistant = ({ show, onCountrySelect }) => {
  const [isListening, setIsListening] = useState(false);
  const [isActivelyListening, setIsActivelyListening] = useState(false);
  const [isWaitingForPermission, setIsWaitingForPermission] = useState(false);
  const containerRef = useRef();
  const recognitionRef = useRef(null);
  const silenceTimeoutRef = useRef(null);
  const hasDetectedSpeechRef = useRef(false);
  const [volume, setVolume] = useState(0); // State for volume level
  const [parsedCountry, setParsedCountry] = useState(null);
  const [parsedDate, setParsedDate] = useState(null);
  const finalTranscriptRef = useRef("");
  const [recognitionStatus, setRecognitionStatus] = useState("inactive");
  const debounceTimerRef = useRef(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const LOW_VOLUME_THRESHOLD = 15;
  const VOLUME_CHECK_DELAY = 500;

  useEffect(() => {
    if ("SpeechRecognition" in window || "webkitSpeechRecognition" in window) {
      const SpeechRecognition =
        window.SpeechRecognition || window.webkitSpeechRecognition;
      recognitionRef.current = new SpeechRecognition();
      recognitionRef.current.continuous = true;
      recognitionRef.current.interimResults = true;
      recognitionRef.current.lang = "en-US";
      recognitionRef.current.maxAlternatives = 1;

      recognitionRef.current.onstart = () => {
        setIsActivelyListening(true);
        setRecognitionStatus("active");
        console.log("Speech recognition started");
        setIsWaitingForPermission(false);
      };

      recognitionRef.current.onaudiostart = () => {
        console.log("Audio capturing started");
      };

      recognitionRef.current.onresult = (event) => {
        let interimTranscript = "";
        let finalTranscript = "";

        for (let i = event.resultIndex; i < event.results.length; ++i) {
          if (event.results[i].isFinal) {
            finalTranscript += event.results[i][0].transcript;
          } else {
            interimTranscript += event.results[i][0].transcript;
          }
        }

        if (finalTranscript) {
          finalTranscriptRef.current = finalTranscript;
          console.log("Final transcript:", finalTranscript);
          
          // New: Try to parse the transcript immediately when we get a final result
          const { country, date } = parseSpeechInput(finalTranscript);
          if (country && date) {
            // If we successfully parsed both country and date, stop listening
            recognitionRef.current?.stop();
            if (typeof onCountrySelect === 'function') {
              onCountrySelect({ country, date });
            }
          }
        }

        if (interimTranscript || finalTranscript) {
          if (!hasDetectedSpeechRef.current) {
            hasDetectedSpeechRef.current = true;
            console.log("Speech detected, starting silence timeout with 700ms");
          }
          resetSilenceTimeout();
        }

        if (interimTranscript) {
          console.log("Interim transcript:", interimTranscript);
        }
      };

      recognitionRef.current.onend = () => {
        setIsListening(false);
        setIsActivelyListening(false);
        setVolume(0);
        setIsProcessing(false);
        setRecognitionStatus("inactive");
        console.log("Speech recognition ended");
        hasDetectedSpeechRef.current = false;
        
        // Only process the final transcript if it hasn't been processed in onresult
        if (finalTranscriptRef.current) {
          const { country, date } = parseSpeechInput(finalTranscriptRef.current);
          if (country && !date) {  // Only process if we haven't already handled it
            if (typeof onCountrySelect === 'function') {
              onCountrySelect({ country, date });
            }
          }
          finalTranscriptRef.current = "";
        }
      };

      recognitionRef.current.onerror = (event) => {
        console.error("Speech recognition error", event.error);
        setIsListening(false);
        hasDetectedSpeechRef.current = false;
        setIsWaitingForPermission(false);
      };
    }

    return () => {
      if (recognitionRef.current) {
        recognitionRef.current.stop();
      }
      clearTimeout(silenceTimeoutRef.current);
      clearTimeout(debounceTimerRef.current);
      hasDetectedSpeechRef.current = false;
      setRecognitionStatus("inactive");
    };
  }, []);

  // Web Audio API setup
  useEffect(() => {
    if (!isListening) return;

    const audioContext = new (window.AudioContext ||
      window.webkitAudioContext)();
    const analyser = audioContext.createAnalyser();
    analyser.fftSize = 256;
    const dataArray = new Uint8Array(analyser.frequencyBinCount);
    let lowVolumeStartTime = null;

    navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
      const source = audioContext.createMediaStreamSource(stream);
      source.connect(analyser);

      const updateVolume = () => {
        analyser.getByteFrequencyData(dataArray);
        const volumeLevel =
          dataArray.reduce((a, b) => a + b, 0) / dataArray.length;
        setVolume(volumeLevel); // Update volume state

        if (hasDetectedSpeechRef.current) {
          if (volumeLevel < LOW_VOLUME_THRESHOLD) {
            if (!lowVolumeStartTime) {
              lowVolumeStartTime = Date.now();
            } else if (Date.now() - lowVolumeStartTime > VOLUME_CHECK_DELAY) {
              setIsProcessing(true);
            }
          } else {
            lowVolumeStartTime = null;
            setIsProcessing(false);
          }
        }

        requestAnimationFrame(updateVolume);
      };
      updateVolume();
    });

    return () => {
      audioContext.close();
      setIsProcessing(false);
    };
  }, [isListening]);

  const resetSilenceTimeout = (duration = 5000) => {
    if (silenceTimeoutRef.current) {
      clearTimeout(silenceTimeoutRef.current);
    }

    silenceTimeoutRef.current = setTimeout(() => {
      if (hasDetectedSpeechRef.current) {
        console.log("User is likely done talking");
      } else {
        console.log("No speech detected within the initial timeout");
      }
      recognitionRef.current?.stop();
      setIsListening(false);
      hasDetectedSpeechRef.current = false;
    }, duration);
  };

  const toggleListening = useCallback(() => {
    if (debounceTimerRef.current) {
      clearTimeout(debounceTimerRef.current);
    }

    debounceTimerRef.current = setTimeout(async () => {
      if (recognitionStatus === "active") {
        recognitionRef.current?.stop();
      } else if (recognitionStatus === "inactive") {
        try {
          const permissionStatus = await navigator.permissions.query({
            name: "microphone",
          });
          if (permissionStatus.state === "granted") {
            startListening();
          } else {
            setIsWaitingForPermission(true);
            startListening();
          }
        } catch (error) {
          console.error("Error checking microphone permission:", error);
          startListening();
        }
      }
    }, 300); // 300ms debounce
  }, [recognitionStatus]);

  const startListening = () => {
    try {
      recognitionRef.current?.start();
      setIsListening(true);
      console.log("Started listening");
      hasDetectedSpeechRef.current = false;
      resetSilenceTimeout(10000);
    } catch (error) {
      console.error("Error starting speech recognition:", error);
    }
  };

  const findBestMatch = (input) => {
    const matches = fuzzball.extract(input, countries, {
      scorer: fuzzball.ratio,
      limit: 1,
      cutoff: 65, // Minimum similarity score (0-100)
    });

    return matches.length > 0 ? matches[0][0] : null;
  };

  const parseSpeechInput = (transcript) => {
    const lowerTranscript = transcript.toLowerCase();
    let country = null;
    let date = null;

    // **Step 1: Extract Country Name**
    const parts = lowerTranscript.split(",");

    if (parts.length === 0) {
      console.error("Transcript does not contain expected format 'Country, Year'");
      return { country, date };
    }

    const countryInput = parts[0].trim();

    // **Step 2: Fuzzy Match the Country**
    country = findBestMatch(countryInput);

    if (!country) {
      console.error(`No matching country found for input: "${countryInput}"`);
      return { country, date };
    }

    // **Step 3: Parse only the first Year mentioned**
    const allYearMatches = [...lowerTranscript.matchAll(/\b(\d{2,4})\s*(bc|bce)?\b/gi)];
    
    if (allYearMatches.length > 0) {
      const yearMatch = allYearMatches[0]; // Take only the first match
      let year = parseInt(yearMatch[1], 10);
      const isBC = yearMatch[2] && (yearMatch[2].toLowerCase() === "bc" || yearMatch[2].toLowerCase() === "bce");

      // Rest of the year processing remains the same
      if (year < 100 && !isBC) {
        year += year < 50 ? 2000 : 1900;
      }

      if (isBC) {
        year = -year;
      }

      const roundedYear = Math.round(year / 25) * 25;

      const currentYear = new Date().getFullYear();
      if (roundedYear > currentYear) {
        date = Math.floor(currentYear / 25) * 25;
      } else {
        date = roundedYear;
      }

      if (date < -500) {
        date = -500;
        console.warn("Year is below the minimum limit. Setting to -500 (500 BC).");
      }
    }

    return { country, date };
  };

  return (
    <div
      className={`${styles.tmAssistantContainer} ${show ? styles.visible : styles.hidden}`}
    >
      <div
        className={`${styles.outerOutline} ${isListening ? styles.listeningOutline : ""}`}
        style={{
          transform: `scale(${isActivelyListening ? 1 + volume / 300 : 1})`,
        }} // Apply oscillation only to the outline
      />
      <button
        ref={containerRef}
        className={`${styles.dictationButton} ${recognitionStatus === "active" ? styles.listening : ""}`}
        onClick={toggleListening}
        aria-label="Voice Assistant"
        disabled={recognitionStatus === "transitioning"}
      >
        <MicrophoneIcon
          className={`${styles.microphoneIcon} ${recognitionStatus === "active" ? styles.listeningIcon : ""}`}
        />
        <div className={`${styles.processingOverlay} ${isProcessing ? styles.visible : ""}`}>
          <l-jelly-triangle
            size="33"
            speed="1.75" 
            color="black" 
          ></l-jelly-triangle>
        </div>
      </button>
      {isWaitingForPermission && (
        <div className={styles.permissionMessage}>
          Waiting for microphone permission...
        </div>
      )}
    </div>
  );
};

export default TmAssistant;
