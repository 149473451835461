// AppRoutes.js

import React, { useContext } from "react";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
  Outlet,
} from "react-router-dom";

// Pages
import { AccountHome } from "./pages/AccountHome/AccountHome";
import { AppLayout } from "./AppLayout";
import AppSidebar from "./components/AppSidebar/AppSidebar";
import { HomePage } from "./pages/AppDashboard/AppDashboard";
import { PaymentSuccessPage } from "./pages/PaymentSuccessPage/PaymentSuccessPage";
import { TermsOfUse } from "./pages/Terms/TermsOfUse";
import { GenerativeSearch } from "./pages/GenerativeSearch/GenerativeSearch";
import LandingPage from "./components/LandingPage/LandingPage";
import { Navbar } from "./components/Navbar/Navbar";
import { TimeMachine } from "./pages/TimeMachine/TimeMachine";

// Context
import { AppContext } from "./context/appContext";

// Create a ProtectedRoute component
const ProtectedRoute = ({ element, isAuthenticated }) => {
  return isAuthenticated ? element : <Navigate to="/" replace />;
};

// Create a new layout component that includes AppSidebar
const AppLayoutWithSidebar = () => (
  <div className="flex">
    <AppSidebar />
    <div className="flex-1 ml-64">
      <Navbar />
      <AppLayout>
        <Outlet />
      </AppLayout>
    </div>
  </div>
);

const createRouter = (isAuthenticated) =>
  createBrowserRouter([
    {
      path: "/",
      element: isAuthenticated ? (
        <Navigate to="/time-machine" replace />
      ) : (
        <AppLayout />
      ),
      children: [
        {
          index: true,
          element: <LandingPage />,
        },
        { path: "terms", element: <TermsOfUse /> },
      ],
    },
    {
      element: <AppLayoutWithSidebar />,
      children: [
        {
          path: "account",
          element: (
            <ProtectedRoute
              element={<AccountHome />}
              isAuthenticated={isAuthenticated}
            />
          ),
        },
        {
          path: "payment-success",
          element: (
            <ProtectedRoute
              element={<PaymentSuccessPage />}
              isAuthenticated={isAuthenticated}
            />
          ),
        },
        {
          path: "generative-search",
          element: (
            <ProtectedRoute
              element={<GenerativeSearch />}
              isAuthenticated={isAuthenticated}
            />
          ),
        },
        {
          path: "time-machine",
          element: (
            <ProtectedRoute
              element={<TimeMachine />}
              isAuthenticated={isAuthenticated}
            />
          ),
        },
        // Added the new route for Direct Mode
        {
          path: "direct-mode",
          element: (
            <ProtectedRoute
              element={<HomePage />}
              isAuthenticated={isAuthenticated}
            />
          ),
        },
      ],
    },
    {
      path: "*",
      element: <Navigate to="/" replace />,
    },
  ]);

export const AppRoutes = () => {
  const { isAuthenticated } = useContext(AppContext);
  const router = createRouter(isAuthenticated);

  return <RouterProvider router={router} />;
};
